






































































































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgUpdateThgAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { IThg } from "@/models/thg.entity";
import { UnitEnum } from "@/lib/enum/unit.enum";
import { isNumberWithinRangeRule } from "@/lib/rules/isNumberWithinRangeRule";

@Component({
  components: {}
})
export default class ThgBatchUpdateDialog extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  thgs!: IThg[];

  updatable: IThg[] = [];

  revenue = 0;
  quantity = 0;
  unit = UnitEnum.KWH;
  isFixed = false;

  dialog = false;
  isValid = false;
  isLoading = false;

  averageUpdateInMs = 120;
  sleepInMs = 50;

  get units() {
    return [UnitEnum.KWH, UnitEnum.MWH];
  }

  get minZeroNumberRule() {
    return isNumberWithinRangeRule(0);
  }

  initialize() {
    this.revenue = this.thgs[0].payoutConfiguration.revenue || 0;
    this.quantity = this.thgs[0].payoutConfiguration.quantity || 0;
    this.unit = this.thgs[0].payoutConfiguration.unit || UnitEnum.KWH;
    this.isFixed = this.thgs[0].payoutConfiguration.isFixed || false;
  }

  closeDialog() {
    this.dialog = false;
  }

  get requiredRule() {
    return requiredRule();
  }

  get vins() {
    return this.thgs.map(p => p.registration.identificationnumber);
  }

  async save() {
    const failed: IThg[] = [];

    for (const thg of this.thgs) {
      this.isLoading = true;

      try {
        const sleepInMs = this.sleepInMs;

        await new Promise(resolve => setTimeout(resolve, sleepInMs));

        ThgPortalModule.setSelectedThg(thg);

        const updateThgDto: ThgUpdateThgAdminDtoGen = {
          payoutConfiguration: {
            revenue: this.revenue,
            quantity: this.quantity,
            unit: this.unit,
            isFixed: this.isFixed
          }
        };
        const response = await ThgPortalModule.updateThg(updateThgDto);

        if (!response) {
          this.$toast.error(`Fehler beim ändern der THG ${thg.id}`);
          failed.push(thg);
        }
      } catch (error) {
        failed.push(thg);
        this.$log.error(error);
        this.$toast.error(`Fehler beim ändern der THG ${thg.id}`);
      }
    }

    if (failed.length > 0) {
      this.$toast.error(`Fehler beim Ändern von ${failed.length} THGs`);
      this.$emit("failed", failed);
    } else {
      this.$toast.success(`${this.thgs.length} updated`);
    }

    this.isLoading = false;
    this.closeDialog();
  }
}
