




















































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Watch } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import Debug from "../utility/Debug.vue";
import ThgChargingStationInformationCard from "./ThgChargingStationInformationCard.vue";
import ThgMeterReadingDetailTable from "./ThgMeterReadingDetailTable.vue";
import ThgMeterReadingInformationCard from "./ThgMeterReadingInformationCard.vue";
import ThgQuoteDetailCardActivityTimeLineSideCard from "./ThgQuoteDetailCardActivityTimeLineSideCard.vue";
import ThgQuoteDetailInformationContractCard from "./ThgQuoteDetailInformationContractCard.vue";
import ThgQuoteDetailInformationImpactFactorCard from "./ThgQuoteDetailInformationImpactFactorCard.vue";
import ThgQuoteDetailInformationStatusCard from "./ThgQuoteDetailInformationStatusCard.vue";
import ThgMeterReadingTransferDialog from "./ThgMeterReadingTransferDialog.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    CustomerContactCard,
    ThgMeterReadingInformationCard,
    ThgChargingStationInformationCard,
    ThgMeterReadingDetailTable,
    ThgMeterReadingTransferDialog,
    Debug,
    TemplateDialog,
    ThgQuoteDetailInformationImpactFactorCard,
    ThgQuoteDetailInformationStatusCard,
    ThgQuoteDetailInformationContractCard,
    ThgQuoteDetailCardActivityTimeLineSideCard
  }
})
export default class ThgMeterReadingDetailCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedUser!: IAdminUser;

  /**
   * Internal loading state
   */
  isLoading = false;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  isTimeLineSideCard = false;

  isLoadingActivities = false;

  get selectedChargingStation() {
    return ThgPortalModule.selectedChargingStation;
  }

  get selectedMeterReading() {
    return ThgPortalModule.selectedMeterReading;
  }

  get meterReadings() {
    return ThgPortalModule.meterReadingForChargingStation;
  }

  get hasChargingStationMeterReadings(): boolean {
    return this.meterReadings.length > 0;
  }

  get isSelectedMeterReading() {
    return "id" in (this.selectedMeterReading || {});
  }

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get to() {
    return [this.selectedUser?.contact.email];
  }

  get createdDate() {
    return this.date(this.selectedMeterReading);
  }

  get partner() {
    return PartnerModule.partners.find(p => p.id === this.selectedMeterReading.partnerId);
  }

  @Watch("loading")
  async loadActivities() {
    this.isTimeLineSideCard = false;
    if (this.loading) {
      return;
    }
    try {
      this.isLoadingActivities = true;
      const partnerId = this.selectedMeterReading.partnerId || this.$route.params.partnerId;
      const thgId = this.selectedMeterReading.id || this.$route.params.meterReadingId;
      await ActivityLogModule.loadDocumentsForThg({ partnerId, thgId });
      ActivityLogModule.paginationList.push({
        user: {
          firstName: this.selectedUser?.firstName,
          lastName: this.selectedUser?.lastName
        } as MrfiktivUserViewmodelGen,
        actionType: ActionEnum.CREATE,
        timestamp: this.selectedMeterReading.timestamp,
        source: { refId: this.selectedMeterReading.id, refType: ResourceEnum.THG },
        id: "",
        partnerId,
        activity: ActivityTypeEnum.CREATE
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingActivities = false;
    }
  }

  toDetailView() {
    if (!this.selectedMeterReading.meterReading.chargingStationId) {
      this.$toast.error(this.$t("components.partner.PartnerReportList.noQuoteFound"));
      return;
    }

    this.$router.push({
      name: "ThgChargingStationDetailView",
      params: {
        chargingStationId: this.selectedMeterReading.meterReading.chargingStationId
      }
    });
  }

  date(item: any) {
    if (item.timestamp.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(item.timestamp.created, locale);
    }
    return "";
  }

  async updateStatusCb(status: string, userNotification: boolean) {
    const partnerId = this.selectedMeterReading.partnerId;
    const meterReadingId = this.selectedMeterReading.id;

    await ThgPortalModule.updateSelectedThgMeterReadingStatus({
      partnerId,
      meterReadingId,
      dto: { status: status as any },
      userNotification: userNotification
    });

    const activity = await ActivityLogModule.create({
      partnerId,
      data: {
        source: {
          refType: BackendResourceEnum.THG,
          refId: meterReadingId
        },
        actionType: ActionEnum.UPDATE,
        activity: ActivityTypeEnum.UPDATE_PROGRESS_STATUS,
        comment: status
      }
    });
    ActivityLogModule.addToList(activity);
  }

  async savePayoutConfiguration(updatedThg: IThg) {
    this.isLoading = true;

    if (!updatedThg.meterReading) {
      this.$toast.error(this.$t("components.partner.PartnerReportList.noQuoteFound"));
      return;
    }

    if (!updatedThg.meterReading.chargingStationId) {
      this.$toast.error(this.$t("components.partner.PartnerReportList.noQuoteFound"));
      return;
    }

    try {
      await ThgPortalModule.updateSelectedThgMeterReadingStatus({
        partnerId: this.selectedMeterReading.partnerId,
        meterReadingId: this.selectedMeterReading.id,
        userNotification: false,
        dto: {
          meterReading: {
            startDate: updatedThg.meterReading.startDate,
            endDate: updatedThg.meterReading.endDate,
            amountInKwh: updatedThg.meterReading.amountInKwh,
            chargingStationId: updatedThg.meterReading.chargingStationId
          },
          payoutConfiguration: updatedThg.payoutConfiguration,
          status: this.selectedMeterReading.status,
          year: updatedThg.year
        }
      });
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async saveMeterReading(updatedThg: ThgThgMeterReadingViewModelGen) {
    this.isLoading = true;

    if (!updatedThg.meterReading.chargingStationId) {
      this.$toast.error(this.$t("components.partner.PartnerReportList.noQuoteFound"));
      return;
    }
    try {
      await ThgPortalModule.updateSelectedThgMeterReading({
        partnerId: this.selectedMeterReading.partnerId,
        meterReadingId: this.selectedMeterReading.id,
        userNotification: false,
        dto: {
          meterReading: {
            startDate: updatedThg.meterReading.startDate,
            endDate: updatedThg.meterReading.endDate,
            amountInKwh: updatedThg.meterReading.amountInKwh,
            chargingStationId: updatedThg.meterReading.chargingStationId
          }
        }
      });
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async loadContext(): Promise<ITemplateContext> {
    const context: ITemplateContext = {};

    // TODO

    return context;
  }
}
