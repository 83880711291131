
























































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import ThgQuoteBankingInformationDialog from "@/components/thg/ThgQuoteBankingInformationDialog.vue";
import ThgQuoteDetailInformationCard from "@/components/thg/ThgQuoteDetailInformationCard.vue";
import ThgQuoteTransferDialog from "@/components/thg/ThgQuoteTransferDialog.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop, Watch } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgAdminQuickCheckoutDialog from "./ThgAdminQuickCheckoutDialog.vue";
import ThgQuoteDetailCardActivityTimeLineSideCard from "./ThgQuoteDetailCardActivityTimeLineSideCard.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    CustomerContactCard,
    ConfirmActionDialog,
    ThgQuoteDetailInformationCard,
    ThgQuoteBankingInformationDialog,
    ThgQuoteTransferDialog,
    TemplateDialog,
    ThgAdminQuickCheckoutDialog,
    ThgQuoteDetailCardActivityTimeLineSideCard
  }
})
export default class ThgQuoteDetailCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedUser!: IAdminUser;

  isLoading = false;
  isLoadingActivities = false;
  isDecommissionDialog = false;
  isDecommissioned = this.selectedThg.decommissioningDate ? true : false;
  decommissioningDate = new Date().toISOString().substring(0, 10);

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  CountryCodeEnum = CountryCodeEnum;

  isTimeLineSideCard = false;

  get selectedThg() {
    return ThgPortalModule.selectedThg;
  }

  get isSelectedThg() {
    return "id" in this.selectedThg;
  }

  get registrationImages() {
    return ThgPortalModule.selectedThg.registrationImages;
  }

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get to() {
    if (!this.selectedUser?.contact?.email) {
      return [];
    }

    return [this.selectedUser?.contact.email];
  }

  get from() {
    return PartnerModule.partners;
  }

  get partnerId() {
    return this.selectedThg.partnerId;
  }

  toDetailView() {
    this.$router.push({
      name: "ThgQuoteDetailView",
      params: { thgId: this.selectedThg.id, partnerId: this.selectedThg.partnerId }
    });
  }

  get createdDate() {
    return this.date(this.selectedThg);
  }

  date(item: any) {
    if (item.timestamp.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(item.timestamp.created, locale);
    }
    return "";
  }

  getFlag(countryCode: CountryCodeEnum) {
    return getFlagEmoji(countryCode);
  }

  get partner() {
    return PartnerModule.partners.find(p => p.id === this.selectedThg.partnerId);
  }

  async loadPartners() {
    if (PartnerModule.partners.length === 0) {
      await PartnerModule.getPartners();
    }
  }

  @Watch("loading")
  async loadActivities() {
    this.isTimeLineSideCard = false;
    if (this.loading) {
      return;
    }

    try {
      this.isLoadingActivities = true;
      const partnerId = this.partnerId ?? this.$route.params.partnerId;
      const thgId = this.selectedThg.id ?? this.$route.params.thgId;
      await ActivityLogModule.loadDocumentsForThg({ partnerId, thgId });
      ActivityLogModule.paginationList.push({
        user: {
          firstName: this.selectedUser?.firstName,
          lastName: this.selectedUser?.lastName
        } as MrfiktivUserViewmodelGen,
        actionType: ActionEnum.CREATE,
        timestamp: this.selectedThg.timestamp,
        source: { refId: this.selectedThg.id, refType: BackendResourceEnum.THG },
        id: "",
        partnerId,
        activity: ActivityTypeEnum.CREATE
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingActivities = false;
    }
  }

  async loadContext(): Promise<ITemplateContext> {
    const context: ITemplateContext = {};

    context.thg = this.selectedThg as IThg;
    const partnerId = this.selectedThg.partnerId;
    const userId = this.selectedThg.userId;

    if (PartnerModule.partner._id.toString() === partnerId) {
      context.partner = PartnerModule.partner;
    } else {
      await PartnerModule.getPartnerById(partnerId);
      context.partner = PartnerModule.partner;
    }

    if (this.selectedUser?._id.toString() === userId) {
      context.user = this.selectedUser as MrfiktivUserViewmodelGen;
    } else {
      await AdminUserPaginationModule.getSelectedUser(userId);
      context.user = this.selectedUser as MrfiktivUserViewmodelGen;
    }

    return context;
  }

  async decommission() {
    this.isLoading = true;

    let decommissioningDate = "";

    if (this.isDecommissioned) {
      decommissioningDate = new Date(this.decommissioningDate).toISOString();
    }

    try {
      await ThgPortalModule.decomission({
        decommissioningDate
      });
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isDecommissionDialog = false;
      this.isLoading = false;
    }
  }

  async mounted() {
    this.loadPartners();

    this.isDecommissioned = this.selectedThg.decommissioningDate ? true : false;
    this.decommissioningDate = new Date().toISOString().substring(0, 10);
  }
}
