var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('card', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "subtitle": _vm.i18n['receiverData'],
      "hideDivider": true,
      "hideTitle": true,
      "outlined": false
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_vm.getIsUserFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-user-identity-data', {
    staticClass: "ma-1",
    attrs: {
      "user": _vm.selectedBilledBeing
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-partner-identity-data', {
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.partnerBillingInformation
    }
  }) : _vm._e(), _vm.getIsUserFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-user-contact-data', {
    staticClass: "ma-1",
    attrs: {
      "user": _vm.selectedBilledBeing
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-partner-contact-data', {
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.partnerBillingInformation
    }
  }) : _vm._e(), _vm.getIsUserFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-user-address-data', {
    staticClass: "ma-1",
    attrs: {
      "user": _vm.selectedBilledBeing
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-partner-address-data', {
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.partnerBillingInformation
    }
  }) : _vm._e(), _vm.getIsUserFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-user-company-data', {
    staticClass: "ma-1",
    attrs: {
      "user": _vm.selectedBilledBeing
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-partner-company-data', {
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.partnerBillingInformation
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) ? _c('thg-billing-selection-item-partner-data', {
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.selectedBilledBeing
    }
  }) : _vm.partnerIdsFromSelectedBilledDocuments && _vm.partnerIdsFromSelectedBilledDocuments.size === 1 && _vm.partnerIdsFromSelectedBilledDocuments.values().next().value && _vm.billingType === _vm.BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT ? _c('thg-billing-selection-item-banking-data', {
    key: JSON.stringify(_vm.partnerBankings) + 'additionalBanking',
    staticClass: "ma-1",
    attrs: {
      "title": "Partner Bank",
      "load": true,
      "banking": _vm.partnerBankings.get(_vm.partnerIdsFromSelectedBilledDocuments.values().next().value),
      "partnerId": _vm.partnerIdsFromSelectedBilledDocuments.values().next().value
    }
  }) : _vm.getIsUserFocusedBillingType(_vm.billingType) && _vm.selectedBilledBeing && _vm.billingType !== _vm.BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT ? _c('thg-billing-selection-item-banking-data', {
    key: JSON.stringify(_vm.banking) + 'user',
    staticClass: "ma-1",
    attrs: {
      "load": false,
      "banking": _vm.banking,
      "userId": _vm.selectedBilledBeing._id || _vm.selectedBilledBeing.id
    }
  }) : _vm._e(), _vm.getIsPartnerFocusedBillingType(_vm.billingType) && _vm.selectedBilledBeing ? _c('thg-billing-selection-item-banking-data', {
    key: JSON.stringify(_vm.banking) + 'partner',
    staticClass: "ma-1",
    attrs: {
      "load": false,
      "banking": _vm.banking,
      "partnerId": _vm.selectedBilledBeing._id || _vm.selectedBilledBeing.id
    }
  }) : _vm._e(), _vm.selectedPartners && [_vm.BillingTypeEnum.CREDIT_CHARGING_STATION, _vm.BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT, _vm.BillingTypeEnum.CREDIT].includes(_vm.billingType) ? _c('thg-billing-selection-item-select-partner-data', {
    key: _vm.selectedPartners.map(function (s) {
      return s.id;
    }).join('partnerkey'),
    staticClass: "ma-1",
    attrs: {
      "partner": _vm.selectedPartners,
      "partnerIds": _vm.partnerIdsFromDocuments
    }
  }) : _vm._e(), _vm.billingType === _vm.BillingTypeEnum.CREDIT_PARTNER ? _c('card', {
    attrs: {
      "title": _vm.i18n['provision'],
      "margin": 1
    }
  }, _vm._l(_vm.partnerCommissions, function (partnerCommission) {
    return _c('div', {
      key: partnerCommission.year,
      staticClass: "ma-2 mb-n4"
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.i18n['provision'] + ' ' + partnerCommission.year,
        "type": "number",
        "outlined": "",
        "dense": "",
        "flat": ""
      },
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [!partnerCommission.partnerCommission ? _c('v-icon', {
            staticClass: "billingAlert",
            attrs: {
              "color": "red"
            }
          }, [_vm._v(" mdi-alert-box ")]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: partnerCommission.partnerCommission,
        callback: function callback($$v) {
          _vm.$set(partnerCommission, "partnerCommission", $$v);
        },
        expression: "partnerCommission.partnerCommission"
      }
    })], 1);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "ma-n1"
  }, [_vm.selectedBilledDocumentsPayoutDistribution.length ? _c('bar-chart-card', {
    attrs: {
      "title": _vm.$t('views.ThgBillingBatchSelectionItemView.payoutDistribution'),
      "data": _vm.selectedBilledDocumentsPayoutDistribution
    }
  }) : _vm._e()], 1), _vm.billingType === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION ? _c('thg-billing-batch-number-data', {
    staticClass: "ma-1",
    attrs: {
      "title": _vm.i18n['pricePerKwH'],
      "label": _vm.i18n['pricePerKwH'],
      "value": _vm._pricePerKwH
    },
    on: {
      "update:value": function updateValue($event) {
        _vm._pricePerKwH = $event;
      }
    }
  }) : _vm._e()], 1), _vm.selectedBilledDocuments.length ? _c('v-divider') : _vm._e()], 1), _vm.selectedBilledDocuments.length ? _c('card', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "subtitle": _vm.i18n['referredData'],
      "hideDivider": true,
      "hideTitle": true,
      "outlined": false
    }
  }, [_c('table-wrapper', {
    attrs: {
      "controlElements": _vm.ghgControlElements,
      "headers": _vm.ghgTableHeaders,
      "allItems": _vm.selectedBilledDocuments,
      "selectedItems": _vm._selectedSelectedBilledDocuments,
      "showSelect": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm._selectedSelectedBilledDocuments = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm._selectedSelectedBilledDocuments = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selectActions",
      fn: function fn() {
        return [!_vm.noSelect ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.unselect
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_vm.billingSelectionAlert ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                staticClass: "billingAlert icon mr-1",
                attrs: {
                  "color": "red"
                }
              }, on), [_vm._v(" mdi-alert-box ")])];
            }
          }], null, false, 2728740112)
        }, [_c('span', [_vm._v(_vm._s(_vm.billingSelectionAlert))])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.payoutConfiguration",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.payoutConfiguration ? _c('div', [_vm._v(" " + _vm._s(item.payoutConfiguration.isFixed ? "" : "min.") + " " + _vm._s(item.payoutConfiguration.revenue) + " " + _vm._s("€") + " " + _vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationContractCard.per")) + " " + _vm._s(item.payoutConfiguration.quantity) + " " + _vm._s(item.payoutConfiguration.unit) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.impactFactor",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.impactFactor == 100 ? _c('v-icon', {
          staticClass: "icon",
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" mdi-check ")]) : _vm.getIsGhgSelected(item.id) ? _c('tooltip', {
          attrs: {
            "text": _vm.i18n['impactFactorAlert']
          }
        }, [_c('v-icon', {
          staticClass: "billingAlert icon",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")])], 1) : _vm._e()];
      }
    }, {
      key: "item.vin",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.registration.identificationnumber ? item.registration.identificationnumber : "") + " "), !item.registration.identificationnumber && _vm.getIsGhgSelected(item.id) ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _vm._e()];
      }
    }, {
      key: "item.vehicleClass",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.registration.vehicleClass ? item.registration.vehicleClass : "") + " "), !item.registration.vehicleClass && _vm.getIsGhgSelected(item.id) ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _vm._e()];
      }
    }, {
      key: "item.hasBatch",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [!_vm.getBatchForGhg(item.id) ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.getBatchForGhg(item.id) ? _vm.getBatchForGhg(item.id).name : "") + " " + _vm._s(_vm.getBatchForGhg(item.id) ? _vm.getBatchForGhg(item.id).id : "") + " ")])];
      }
    }, {
      key: "item.batchPrice",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [!_vm.getBatchForGhg(item.id) || _vm.getBatchForGhg(item.id) && !_vm.getBatchForGhg(item.id).pricePerKwh && _vm.getIsGhgSelected(item.id) ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.getBatchForGhg(item.id) && _vm.getBatchForGhg(item.id).pricePerKwh ? _vm.getBatchForGhg(item.id).pricePerKwh.value : "") + " " + _vm._s(_vm.getBatchForGhg(item.id) && _vm.getBatchForGhg(item.id).pricePerKwh ? _vm.getBatchForGhg(item.id).pricePerKwh.unit : "") + " ")])];
      }
    }, {
      key: "item.chargingStationId",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [!(item.chargingStation || item.chargingStation.chargingStationId) ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _c('div', [_vm._v(" " + _vm._s(item.chargingStation.chargingStationId) + " ")])];
      }
    }, {
      key: "item.numberplate",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.getNumberPlate(item)) + " ")];
      }
    }, {
      key: "item.billings",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [(item.billings || []).find(function (b) {
          return b.type === _vm.billingType;
        }) ? _c('v-icon', [_vm._v(" mdi-currency-usd ")]) : _vm._e()];
      }
    }], null, false, 2508683375)
  }), _c('v-divider')], 1) : _vm._e(), _vm.affiliateCodes && _vm.affiliateCodes.length ? _c('card', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "subtitle": _vm.i18n['additionals'],
      "hideDivider": true,
      "hideTitle": true,
      "outlined": false
    }
  }, [_c('table-wrapper', {
    attrs: {
      "controlElements": _vm.affiliateCodeControlElements,
      "headers": _vm.affiliateCodeTableHeaders,
      "allItems": _vm.affiliateCodes,
      "selectedItems": _vm.selectedAffiliateCodes
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedAffiliateCodes = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedAffiliateCodes = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.amountOfThgs",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.getThgsForAffiliate(item.code).length) + " ")];
      }
    }], null, false, 931963620)
  }), _c('v-divider')], 1) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.i18n['ghgDialogTitle'],
      "isDialogActive": _vm.isEditGhgDialogActive,
      "hideRight": true,
      "fullscreen": true,
      "supressKeyboardActions": "👍"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isEditGhgDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isEditGhgDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isEditGhgDialogActive = false;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_vm.billingType === _vm.BillingTypeEnum.CREDIT ? _c('thg-billing-selection-item-batch-data', {
    key: JSON.stringify(_vm.batchData),
    staticClass: "ma-1",
    attrs: {
      "batch": _vm.batchData
    }
  }) : _vm._e(), _vm.billingType === _vm.BillingTypeEnum.CREDIT || _vm.billingType === _vm.BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT ? _c('thg-billing-selection-item-payout-data', {
    staticClass: "ma-1",
    attrs: {
      "thg": _vm.ghgToEdit
    }
  }) : _vm._e(), _vm.ghgToEdit && _vm.billingType === _vm.BillingTypeEnum.CREDIT ? _c('thg-billing-selection-item-registration-data', {
    staticClass: "ma-1",
    attrs: {
      "registration": _vm.ghgToEdit.registration
    }
  }) : _vm._e(), _vm.ghgToEdit && _vm.billingType === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION ? _c('thg-billing-selection-item-meter-reading-data', {
    staticClass: "ma-1",
    attrs: {
      "meterReading": _vm.ghgToEdit.meterReading
    }
  }) : _vm._e(), _vm.billingType === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION || _vm.billingType === _vm.BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT ? _c('thg-billing-selection-thg-base-data', {
    staticClass: "ma-1",
    attrs: {
      "thgBaseData": _vm.ghgToEdit
    }
  }) : _vm._e(), _vm.chargingStation && _vm.billingType === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION ? _c('thg-billing-selection-charging-station-data', {
    staticClass: "ma-1",
    attrs: {
      "chargingStation": _vm.chargingStation
    }
  }) : _vm._e()], 1)]), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.i18n['affiliateDialogTitle'],
      "isDialogActive": _vm.isAffiliateDialogActive,
      "hideRight": true,
      "fullscreen": true,
      "supressKeyboardActions": "aye"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isAffiliateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isAffiliateDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isAffiliateDialogActive = false;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_c('thg-billing-selection-item-affiliate-data', {
    staticClass: "ma-1",
    attrs: {
      "affiliate": _vm.affiliateToEdit
    }
  }), _vm.affiliateToEdit ? _c('thg-billing-selection-item-thg-list-data', {
    staticClass: "ma-1",
    attrs: {
      "thgs": _vm.getThgsForAffiliate(_vm.affiliateToEdit.code)
    }
  }) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }