



















































import ThgCustomerDataAnalyticsExportDialog from "@/components/thg/ThgCustomerDataAnalyticsExportDialog.vue";
import ThgQuoteUpdateMyRegistrationImageDialog from "@/components/thg/ThgQuoteUpdateMyRegistrationImageDialog.vue";
import ThgQuoteVehicleUpdateRegistrationDialog from "@/components/thg/ThgQuoteVehicleUpdateRegistrationDialog.vue";
import ThgVehicleTableLegendDialog from "@/components/thg/ThgVehicleTableLegendDialog.vue";
import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  ThgInsuranceForUserViewModelGen,
  ThgThgCustomerDataViewModelGen,
  ThgThgMeViewModelGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ThgQuickCheckout from "./ThgQuickCheckout.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    ThgCustomerDataAnalyticsExportDialog,
    ThgQuoteVehicleUpdateRegistrationDialog,
    ThgQuoteUpdateMyRegistrationImageDialog,
    ThgVehicleTableLegendDialog,
    ThgQuickCheckout
  }
})
export default class ThgAnalyticsCustomerDataTableCard extends DarkModeHighlightMixin {
  @Prop()
  customerData!: ThgThgCustomerDataViewModelGen[];

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: false })
  hasPem!: boolean;

  search = "";

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  partnerColor = new PartnerColor();

  get headers() {
    return [
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.email"), value: "user.userName" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.firstname"), value: "user.firstName" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.lastname"), value: "user.lastName" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.company"), value: "user.company" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.quotas"), value: "numberOfThgs" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.meterReading"), value: "amountMeterReading" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.insurance"), value: "insurance" },
      { text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.insuranceNumber"), value: "insuranceNumber" }
    ];
  }
  refresh() {
    this.$emit("refresh");
  }

  insuranceNumber(insurance: ThgInsuranceForUserViewModelGen[]) {
    if (insurance.length) {
      return insurance[0].number;
    }
  }

  insurance(insurance: ThgInsuranceForUserViewModelGen[]) {
    if (insurance.length) {
      return insurance[0].name;
    }
  }

  numberOfThgs(thgs: ThgThgMeViewModelGen[]) {
    return thgs.length;
  }

  amountMeterReadings(meterReadings: ThgThgMeterReadingViewModelGen[]) {
    let sum = 0;
    for (const meterReading of meterReadings) {
      sum = sum + (meterReading?.meterReading?.amountInKwh || 0);
    }

    sum / 1000;

    return sum.toLocaleString("de-DE");
  }

  userCreatedDate(user?: IAdminUser) {
    if (user) {
      if (user.timestamps) {
        return new Date(user.timestamps.created).toLocaleString("de-DE", this.dateOptions as any);
      }
    }
  }
}
