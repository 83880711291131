














































































































































































































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import AnalyticsQueryFilter, {
  AnalyticsQueryFilterEmitChangeData
} from "@/components/analytics/AnalyticsQueryFilter.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import ScatterLineChartCard from "@/components/analytics/ScatterLineChartCard.vue";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Component } from "vue-property-decorator";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    AnalyticsQueryFilter,
    TheLayoutPortal,
    TimeSeriesLineChartCard,
    BarChartCard,
    PieChartCard,
    AnalyticsDateRangeSelectorDialog,
    ScatterLineChartCard
  }
})
export default class ThgAnalytics extends PartnerFallbackMixin {
  tabs = 0;
  isLoading = false;

  countryCodeYearFilter: IPageFilterElement[] = [];

  dataLoadingOrder = [
    "realTimeData",
    "userRealTimeData",
    "userTrendAnalysis",
    "trendAnalysis",
    "meaterReadingTrendAnalysis",
    "promotionTrendAnalysis",
    "promotionCountAnalysis",
    "partnerDistribution",
    "marketingOptIn",
    "userIsCompanyDistribution",
    "yearAnalysisData",
    "isMultiYearAnalysisData",
    "weekdayDistribution",
    "hourDistribution",
    "vehicleClassDistribution",
    "impactTypeDistribution",
    "statusDistribution",
    "thgPerUserStats",
    "affiliateDistribution",
    "confirmedByDistribution"
  ];

  dataHandler = {
    trendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getTrendAnylsisData();
      },
      isLoading: false
    },
    userTrendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getUserTrendAnylsisData();
      },
      isLoading: false
    },
    meaterReadingTrendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getMeterReadingTrendAnylsisData();
      },
      isLoading: false
    },
    promotionTrendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getThgPromotionTrendAnalysisData();
      },
      isLoading: false
    },
    promotionCountAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getThgPromotionCountAnalysisData();
      },
      isLoading: false
    },
    realTimeData: {
      getData: async () => {
        await ThgAnalyticsModule.getRealTimeData();
      },
      isLoading: false
    },
    marketingOptIn: {
      getData: async () => {
        await ThgAnalyticsModule.getUserMarketingDistribution();
      },
      isLoading: false
    },
    userIsCompanyDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getUserAnalysisCompanyDistribution();
      },
      isLoading: false
    },
    thgPerUserStats: {
      getData: async () => {
        await ThgAnalyticsModule.getThgPerUserStats();
      },
      isLoading: false
    },
    userRealTimeData: {
      getData: async () => {
        await ThgAnalyticsModule.getUserRealTimeData();
      },
      isLoading: false
    },
    yearAnalysisData: {
      getData: async () => {
        await ThgAnalyticsModule.getYearAnalysisData();
      },
      isLoading: false
    },
    isMultiYearAnalysisData: {
      getData: async () => {
        await ThgAnalyticsModule.getIsMultiYearAnalysisData();
      },
      isLoading: false
    },
    weekdayDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getWeekdayDistributionData();
      },
      isLoading: false
    },
    hourDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getHourDistributionData();
      },
      isLoading: false
    },
    partnerDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getPartnerDistributionData();
      },
      isLoading: false
    },
    affiliateDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getAffiliateDistributionData();
      },
      isLoading: false
    },
    statusDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getStatusDistributionData();
      },
      isLoading: false
    },
    impactTypeDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getImpactTypeDistributionData();
      },
      isLoading: false
    },
    vehicleClassDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getVehicleClassDistributionData();
      },
      isLoading: false
    },
    confirmedByDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getConfirmedByDistribution();
      },
      isLoading: false
    }
  };

  async mounted() {
    this.isLoading = true;

    await Promise.all([
      ThgAnalyticsModule.getAvailableCountries(),
      ThgAnalyticsModule.getAvailableYears(),
      this.getData()
    ]);

    this.isLoading = false;
  }

  async getData() {
    this.setLoading(true);
    for (const key of this.dataLoadingOrder) {
      try {
        await this.dataHandler[key].getData();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    }
  }

  setLoading(value: boolean) {
    for (const key of this.dataLoadingOrder) {
      this.dataHandler[key].isLoading = value;
    }
  }

  async onQueryFilterChange({ filter, field }: AnalyticsQueryFilterEmitChangeData) {
    const filterIndex = this.countryCodeYearFilter.map(c => c.key).indexOf(field as string);

    if (filter.length > 0) {
      // If we haven't save such a filter yet push it to the array of filters
      if (filterIndex === -1) {
        this.countryCodeYearFilter.push(...filter);
      } else {
        // If we have this filter already, replace it
        this.countryCodeYearFilter[filterIndex] = filter[0];
      }
    } else {
      // if the filter field is not saved yet or unknown(invalid field)
      if (filterIndex === -1) {
        this.countryCodeYearFilter = [];
      } else {
        this.countryCodeYearFilter.splice(filterIndex, 1);
      }
    }

    ThgAnalyticsModule.setCountryCodeYearFilter(this.countryCodeYearFilter);
    await this.getData();
  }

  async setDateRange(dateRange: string[]) {
    ThgAnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  /**
   * Convert data to MWh by dividing by 1000
   * @example value: 2000 -> "2.0"
   * @param data actual "meter reading" reading
   */
  private normalizeMeterReadings(data: number): number {
    const fixed = (data / 1000).toFixed(2);

    if (isNaN(Number(fixed))) {
      throw new Error("Data is not a number!");
    }

    return Number(fixed);
  }

  get dates() {
    return ThgAnalyticsModule.dateRange;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get countries() {
    return ThgAnalyticsModule.availableCountries;
  }

  get years() {
    return ThgAnalyticsModule.availableYears;
  }

  get realTimeAnalysisData() {
    return [
      {
        name: "User",
        type: "bar",
        data: ThgAnalyticsModule.userRealtimeData.data.currentTrend
      },
      {
        name: "THG-Quoten",
        type: "bar",
        data: ThgAnalyticsModule.realtimeData.data.currentTrend
      }
    ];
  }

  get realTimeTrendKpiGroup() {
    return [
      {
        icon: "mdi-account",
        value: ThgAnalyticsModule.userRealtimeData.data.sum,
        name: "User"
      },
      {
        icon: "mdi-certificate-outline",
        value: ThgAnalyticsModule.realtimeData.data.sum,
        name: "Quoten"
      }
    ];
  }

  get dayTrendKpiGroup() {
    return [
      {
        icon: "mdi-account",
        value: ThgAnalyticsModule.userTrendAnalysisData.data.sum,
        name: "User"
      },
      {
        icon: "mdi-certificate-outline",
        value: ThgAnalyticsModule.trendAnalysisData.data.sum,
        name: "Quoten"
      },
      {
        icon: "mdi-ev-station",
        value: this.normalizeMeterReadings(ThgAnalyticsModule.meterReadingTrendAnalysisData.data.sum),
        name: "MWh"
      },
      {
        icon: "mdi-currency-eur",
        value: ThgAnalyticsModule.thgPromotionTrendAnalysisData.data.sum,
        name: "Promotion"
      }
    ];
  }

  get dayTrendData() {
    /** Cast to right type and map the meter readings to be in MWh */
    const meterReadingTrendAnalysisDataTyped = (ThgAnalyticsModule.meterReadingTrendAnalysisData.data
      .dayTrend as unknown) as number[][];
    const meterReadingNormalizedTrendData = meterReadingTrendAnalysisDataTyped.map(([timestamp, value]) => [
      timestamp,
      this.normalizeMeterReadings(value)
    ]);

    return [
      {
        name: "User",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.userTrendAnalysisData.data.dayTrend,
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      },
      {
        name: "THG-Quoten",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.dayTrend,
        markPoint: {
          data: [
            { type: "max", name: "Max" },
            { type: "min", name: "Min" }
          ]
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      },
      {
        name: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.meterReading"),
        type: "line",
        symbol: "none",
        data: meterReadingNormalizedTrendData,
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      },
      {
        name: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.promotion"),
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.thgPromotionCountAnalysisData.data.dayTrend,
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      }
    ];
  }

  get trendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get userTrendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.userTrendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.userTrendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.userTrendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get meaterReadingTrendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get promotionTrendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.thgPromotionTrendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.thgPromotionTrendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.thgPromotionTrendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get partnerDistributionData() {
    return ThgAnalyticsModule.partnerDistributionData.data.partnerDistribution;
  }

  get marketingDistributionData() {
    return ThgAnalyticsModule.userMarketingOptinDistribution.data;
  }

  get confirmedByDistribution() {
    return ThgAnalyticsModule.confirmedByDistribution.data;
  }

  get userIsCompanyDistributionData() {
    return ThgAnalyticsModule.userIsCompanyDistribution.data;
  }

  get affiliateDistributionData() {
    return ThgAnalyticsModule.affiliateDistributionData.data;
  }

  get partnerTypeDistribution() {
    return ThgAnalyticsModule.partnerDistributionData.data.partnerTypeDistribution;
  }

  get statusDistributionData() {
    return ThgAnalyticsModule.statusDistributionData.data;
  }

  get impactTypeDistributionData() {
    return ThgAnalyticsModule.impactTypeDistributionData.data;
  }

  get vehicleClassDistributionData() {
    return ThgAnalyticsModule.vehicleClassDistributionData.data;
  }

  get weekdayDistributionData() {
    return ThgAnalyticsModule.weekdayDistributionData.data;
  }

  get hourDistributionData() {
    return ThgAnalyticsModule.hourDistributionData.data;
  }

  get yearDistributionData() {
    return ThgAnalyticsModule.yearAnalysisData.data;
  }

  get isMultiYearDistributionData() {
    return ThgAnalyticsModule.isMultiYearAnalysisData.data;
  }

  get thgUserStats() {
    return {
      type: "line",
      symbol: "none",
      data: ThgAnalyticsModule.thgPerUserStats.chartData
    };
  }

  get thgUserStatsExportData() {
    return ThgAnalyticsModule.thgPerUserStats.data;
  }
}
