






























import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { HttpException } from "@/lib/exceptions/http";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ThgAdminUpdateCountryCodeDialog from "./ThgAdminUpdateCountryCodeDialog.vue";

@Component({
  components: { Card, ThgAdminUpdateCountryCodeDialog }
})
export default class ThgQuoteCountryCodeCard extends DarkModeHighlightMixin {
  @Prop({ default: {} })
  thg!: IThg;

  @Prop()
  cb!: Function;

  loading = false;

  CountryCodeEnum = CountryCodeEnum;

  getFlag(countryCode: CountryCodeEnum) {
    return getFlagEmoji(countryCode);
  }

  async save(status: string) {
    this.loading = true;
    try {
      await this.cb(status);
      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      } else {
        this.$toast.error("Country Code konnte nicht geändert werden");
      }
    } finally {
      this.loading = false;
    }
  }
}
