

















































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ThgAdminUpdatePayoutConfigurationDialog from "./ThgAdminUpdatePayoutConfigurationDialog.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import { UnitEnum } from "@/lib/enum/unit.enum";
import { IUser } from "@/models/user.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ActivePrice } from "@/store/models/thg/active-price";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";

@Component({
  components: { Card, ThgAdminUpdatePayoutConfigurationDialog },
  filters: { simpleDate }
})
export default class ThgQuoteDetailInformationContractCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  thg!: IThg;

  @Prop({ default: undefined })
  user!: IUser;

  @Prop({ default: false })
  loading!: boolean;

  get calculatedPayout() {
    const THG_VALUE_AT = 1500;

    let thgValue = 0;
    if (this.thg.countryCode === CountryCodeEnum.austria) {
      thgValue = THG_VALUE_AT;
    } else {
      new ActivePrice([]).vehicleClassInformation(this.thg.registration.vehicleClass as ThgVehicleTypes)?.amountInKwh;
    }
    if (!thgValue) {
      return "";
    }

    const revenue = this.thg.payoutConfiguration.revenue;
    const quantity = this.thg.payoutConfiguration.quantity;

    let unit = 1;
    switch (this.thg.payoutConfiguration.unit) {
      case UnitEnum.KWH:
        unit = 1;
        break;
      case UnitEnum.MWH:
        unit = 1000;
        break;
      case UnitEnum.PIECE:
        unit = 2000;
        break;
    }

    const result = `${this.thg.registration.vehicleClass} = ${Math.round(
      (revenue / (quantity * unit)) * thgValue * 100
    ) / 100} €`;

    return result;
  }

  get reverseChargeProcedureText() {
    const reverseChargeProcedure = `${$t(
      "components.thg.ThgQuoteDetailInformationContractCard.reverseChargeProcedure"
    )}`;
    let reason = "";
    if (this.user.taxnumber?.toUpperCase().startsWith(CountryCodeEnum.austria)) {
      reason = `${$t("components.thg.ThgQuoteDetailInformationContractCard.austriaDueToTax")}`;
    } else if (this.user?.address?.countryCode === CountryCodeEnum.austria) {
      reason = `${$t("components.thg.ThgQuoteDetailInformationContractCard.austriaDueToAddress")}`;
    } else if (this.user?.countryCode === CountryCodeEnum.austria) {
      reason = `${$t("components.thg.ThgQuoteDetailInformationContractCard.austriaDueToFlag")}`;
    }

    if (!reason) return "";

    return `${reverseChargeProcedure} (${reason})`;
  }

  get payout() {
    if (this.thg.payoutConfiguration.isFixed) {
      return (
        this.$t("components.thg.ThgQuoteDetailInformationContractCard.fix") +
        this.thg?.payoutConfiguration?.revenue?.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        })
      );
    }
    return (
      this.$t("components.thg.ThgQuoteDetailInformationContractCard.atLeast") +
      this.thg?.payoutConfiguration?.revenue?.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })
    );
  }

  get promotion() {
    const value = this.thg?.promotionConfiguration?.value?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });

    const type = $t(`common.PromotionTypeEnum.${this.thg?.promotionConfiguration?.type}`);

    return `${type} ${value}`;
  }

  save(updateThg: IThg) {
    this.$emit("save", updateThg);
  }

  toPartnerDetailView() {
    const roueData = this.$router.resolve({
      name: "PartnerDetailView",
      params: { partnerId: this.thg.partnerId }
    });

    window.open(roueData.href, "_blank");
  }
}
