var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": "#".concat(_vm.value.billingNumber),
      "alerts": _vm.alerts,
      "actions": _vm.actions,
      "breadCrumbs": _vm.breadCrumbs,
      "chips": _vm.chips
    },
    on: {
      "actionClicked": _vm.processAction,
      "alertClicked": _vm.processAction
    }
  }), _c('v-divider'), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("createdOn", {
    date: _vm.createdDate
  })) + " ")]), _c('v-row', {
    staticClass: "ma-1"
  }, [_vm.isLeftColumn ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": _vm.isRightColumn ? 8 : 12
    }
  }, [_vm.pdf ? _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentDetail.file")) + " "), _c('v-spacer'), _c('file-download', {
    ref: "download",
    attrs: {
      "icon": false,
      "hidden": "",
      "downloadFiles": [{
        url: _vm.pdf.url,
        filename: _vm.pdf.title
      }]
    }
  })], 1), _c('activity-time-line-item-document-card-images', {
    attrs: {
      "document": _vm.pdf
    }
  })], 1) : _vm._e(), _vm.accountRecords.length ? _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCsvSelection.accountRecord")) + " ")]), _c('table-wrapper', {
    attrs: {
      "headers": _vm.headers,
      "allItems": _vm.accountRecords,
      "hideTableHeader": true,
      "hideSearch": true,
      "hideTableFooter": true
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.isRightColumn ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": _vm.isLeftColumn ? 4 : 12
    }
  }, [_vm.isUserLoading || _vm.user ? _c('div', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("BackendResourceEnum.user")))]), _vm.isUserLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _vm.user ? _c('div', [_c('refs-user', {
    attrs: {
      "item": _vm.user
    },
    on: {
      "openDetail": _vm.goToUserDetail
    }
  }), _c('v-list-item', {
    staticClass: "mt-n6"
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelectionContactData.isTaxDeductible")) + " "), _vm.user.isTaxDeductible ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-check ")]) : _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-close ")])], 1), _vm.user.isTaxDeductible ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("getFlagEmoji")(_vm.user.countryCode)) + " " + _vm._s(_vm.user.taxnumber) + " ")]) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.isPartnerLoading || _vm.partner ? _c('div', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("BackendResourceEnum.partner")))]), _vm.isPartnerLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _vm.partner ? _c('refs-partner', {
    attrs: {
      "item": _vm.partner
    },
    on: {
      "openDetail": _vm.goToPartnerDetail
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.value.referenceIds && _vm.value.referenceIds.length && _vm.value.partnerId ? _c('div', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("navigation.TheThgAdminList.allThgs")))]), _vm._l(_vm.value.referenceIds || [], function (referenceId, index) {
    return _c('div', {
      key: 'reference' + referenceId,
      staticClass: "px-4"
    }, [index < 3 ? _c('a', {
      on: {
        "click": function click($event) {
          return _vm.goToThgDetail(_vm.value.partnerId, referenceId);
        }
      }
    }, [_c('small', [_vm._v(" " + _vm._s(referenceId) + " ")])]) : _vm._e()]);
  })], 2) : _vm._e()]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }