






















import ThgBatchUpdateDialog from "@/components/thg/ThgBatchUpdateDialog.vue";
import ThgMultiQuickCheckoutDialog from "@/components/thg/ThgMultiQuickCheckoutDialog.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { IThgVehicleClass } from "@/lib/interfaces/thg/thg-vehicle-class.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { thgVehicleClassMap } from "@/lib/utility/thgVehicleClassMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgUpdateThgAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { IUpdateThgStatusDto } from "@/store/interface/thg/thg-update-status.interface";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Watch } from "vue-property-decorator";
import { ImpactTypeEnum } from "../thg/enum/impact-type.enum";
import ThgBatchUpdateItemsByVinDialog from "@/components/thg/ThgBatchUpdateItemsByVinDialog.vue";
import ThgBillingRecreationIndividualDialog from "@/components/thg/ThgBillingRecreationIndividualDialog.vue";
import ThgQuoteTransferDialog from "@/components/thg/ThgQuoteTransferDialog.vue";
import { IThg } from "@/models/thg.entity";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    TheLayoutPortal,
    ThgBatchUpdateDialog,
    ThgMultiQuickCheckoutDialog,
    ThgBatchUpdateItemsByVinDialog,
    ThgBillingRecreationIndividualDialog,
    ThgQuoteTransferDialog,
    PaginatedTable
  },
  filters: {
    getFlagEmoji,
    simpleDate
  }
})
export default class ThgTable extends DarkModeHighlightMixin {
  readonly store = ThgPaginationModule;

  isLoadingAll = false;
  isLoading = false;

  search = "";
  filter = false;

  selected: IThg[] = [];
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  /**
   * All available country codes
   */
  countryCodes = Object.keys(CountryCodeEnum).map(k => CountryCodeEnum[k as any]);

  country: CountryCodeEnum | string = "";

  /**
   * All available status
   */
  statusCodes = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);
  status: ThgStatusEnum | string = "";

  impactTypeCodes = Object.keys(ImpactTypeEnum).map(k => ImpactTypeEnum[k as any]);
  impactType: ImpactTypeEnum | string = "";

  year = 0;
  vehicleClass = "";
  vin = "";
  numberplate = "";

  updateSelected(selectedThgs: IThg[]) {
    this.selected = selectedThgs;
  }

  // id, partnerId, userId, countryCode, status, vin, payoutconfiguration, timestamp,

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100
      },
      {
        text: "Land",
        value: "countryCode",
        width: 90
      },
      {
        text: "Status",
        value: "status",
        width: 90
      },
      {
        text: "Jahr",
        value: "year",
        width: 90
      },
      {
        text: "Numberplate",
        value: "numberplate",
        width: 125
      },
      {
        text: "Klasse",
        value: "registration.vehicleClass",
        width: 90
      },
      {
        text: "VIN",
        value: "registration.identificationnumber",
        width: "20%"
      },

      {
        text: $t("components.thg.ThgQuoteDetailInformationContractCard.changePayout"),
        value: "payoutConfiguration.revenue",
        width: 90
      },
      {
        text: $t("components.thg.ThgQuoteDetailInformationContractCard.quantity"),
        value: "payoutConfiguration.quantity",
        width: 90
      },
      {
        text: $t("components.thg.ThgQuoteDetailInformationContractCard.unit"),
        value: "payoutConfiguration.unit",
        width: 90
      },

      {
        text: "fixed",
        value: "payoutConfiguration.isFixed",
        width: 90
      },
      {
        text: "Impact Type",
        value: "impactType",
        width: "20%"
      },
      {
        text: "Impact Factor",
        value: "impactFactor",
        width: "20%"
      },
      { text: "Id", align: "start", value: "id", width: 100 },
      { text: "Partner", align: "start", value: "partnerId", width: 100 },
      { text: "User", align: "start", value: "userId", width: 100 }
    ];
  }

  get thgs(): IThg[] {
    return ThgPaginationModule.paginationList;
  }

  async onCreated() {
    this.store.fetchFirstPage({});
  }

  async update(updated: IThg) {
    this.isLoading = true;
    try {
      ThgPortalModule.setSelectedThg(updated);

      const updateThgDto: ThgUpdateThgAdminDtoGen = {
        payoutConfiguration: updated.payoutConfiguration
      };
      const response = await ThgPortalModule.updateThg(updateThgDto);

      if (!response) {
        this.$toast.error("Fehler beim ändern der THG");
      } else {
        this.$toast.success("THG erfolgreich geändert.");
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim ändern der THG");
    } finally {
      this.isLoading = false;
    }
  }

  async updateStatus(updated: IThg) {
    this.isLoading = true;
    try {
      ThgPortalModule.setSelectedThg(updated);

      const dto: IUpdateThgStatusDto = {
        dto: { status: updated.status },
        noUserNotification: true
      };
      const response = await ThgPortalModule.updateSelectedThgStatus(dto);

      if (!response) {
        this.$toast.error("Fehler beim ändern der THG");
      } else {
        this.$toast.success("THG erfolgreich geändert.");
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim ändern der THG");
    } finally {
      this.isLoading = false;
    }
  }

  thgStatusMap = thgStatusMap;

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  thgVehicleClassMap = thgVehicleClassMap;

  thgVehicleClass(thgVehicleClass: any): IThgVehicleClass | undefined {
    return this.thgVehicleClassMap.get(thgVehicleClass);
  }

  close() {
    this.$log.info("Dialog closed");
  }

  @Watch("selected")
  watchSelected() {
    this.$log.info(this.selected);
  }

  async selectFailed(thgs: IThg[]) {
    await this.store.fetchFirstPage({});
    this.selected = thgs;
  }
}
