




































































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgPriceViewModelGen, ThgUpdatePriceDtoGen } from "@/services/thg/v1/data-contracts";
import { CreatePriceDto } from "@/store/models/thg/create-price.dto";
import { PartnerModule } from "@/store/modules/partner";
import { PriceModule } from "@/store/modules/price.store";
import { Component, Vue } from "vue-property-decorator";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import * as XLSX from "xlsx";
import ThgPriceForm from "@/views/thgPortal/ThgPriceForm.vue";
import ThgPriceImport from "@/views/thgPortal/ThgPriceImport.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    ConfirmActionDialog,
    ThgPriceForm,
    FilterCardPagination,
    ThgPriceImport,
    PaginatedTable
  },
  filters: {
    simpleDoubleDigitDate,
    getFlagEmoji
  }
})
export default class ThgPriceTable extends Vue {
  readonly pagination = PriceModule;

  isLoadingAll = false;
  isLoading = false;

  predefinedFilter = [
    {
      name: "components.FilterCardPagination.predefinedFilter.price.active",
      filter: [
        {
          key: "isActive",
          operation: "$eq",
          value: true
        },
        {
          key: "isArchived",
          operation: "$eq",
          value: false
        }
      ]
    },
    {
      name: "components.FilterCardPagination.predefinedFilter.price.archived",
      filter: [
        {
          key: "isActive",
          operation: "$eq",
          value: false
        }
      ]
    },
    {
      name: "components.FilterCardPagination.predefinedFilter.price.new",
      filter: [
        {
          key: "isArchived",
          operation: "$eq",
          value: true
        }
      ]
    }
  ];

  search = "";

  isFilterDisplayed = false;
  isActivePriceDisplayed = false;

  isNewDialogActive = false;
  isCreateDialogActive = false;
  isActivateDialogActive = false;
  isDeleteDialogActive = false;
  isDialogLoading = false;
  isImportDialogActive = false;

  isCreateValid = false;

  snack = false;
  snackColor = "";
  snackText = "";

  selected: ThgPriceViewModelGen[] = [];

  progressCounter = 0;

  countryCodes = Object.keys(CountryCodeEnum).map(k => CountryCodeEnum[k as any]);

  countryFilter: CountryCodeEnum | string = "";
  yearFilter = 0;
  valueFilter = 0;
  commissionFilter = 0;
  isActiveFilter = false;
  isFixedFilter = false;

  newPriceForYear = new Date().getFullYear();
  newBasePrice = 0;
  newPriceValueChangeFactor = 1;
  newPriceStartDate = new Date().toISOString();
  newPriceEndDate = new Date(new Date().getFullYear() + 1, 1, 25).toISOString();

  _createPrice: CreatePriceDto = new CreatePriceDto();

  /**
   * create new prices
   */
  _newPrices: CreatePriceDto[] = [];
  item: any;
  getFlagEmoji: string | undefined;
  simpleDoubleDigitDate: any;

  /**
   *
   * @param date
   * @param locale
   */
  simpleDate(date: string, locale?: string) {
    return simpleDate(date, locale);
  }

  get paginationFilterList(): PaginationFilterListElement[] {
    return PriceModule.filterList;
  }

  get paginationFilter(): IPageFilterElement[] {
    return PriceModule.filter;
  }

  set paginationFilter(filter: IPageFilterElement[]) {
    PriceModule.setFilter(filter);
  }

  priceStatus(item: ThgPriceViewModelGen) {
    if (item.isArchived) {
      return {
        text: "Archiviert",
        color: "error"
      };
    }
    if (item.isActive) {
      return {
        text: "Aktiv",
        color: "success"
      };
    }

    return {
      text: "Erstellt",
      color: "info"
    };
  }

  get activateTableHeader() {
    return [
      {
        text: this.$t("thg.PriceTable.partnerId"),
        align: "start",
        value: "partnerId"
      },
      {
        text: this.$t("thg.PriceTable.year"),
        align: "start",
        value: "year"
      },
      {
        text: this.$t("thg.PriceTable.isFixed"),
        align: "start",
        value: "isFixed"
      },
      {
        text: this.$t("thg.PriceTable.value"),
        align: "start",
        value: "value"
      },
      {
        text: this.$t("thg.PriceTable.commission"),
        align: "start",
        value: "commission"
      },
      { text: this.$t("thg.PriceTable.id"), align: "start", value: "id" }
    ];
  }

  get headers() {
    return [
      {
        text: this.$t("thg.PriceTable.isActive"),
        align: "start",
        value: "isActive"
      },
      {
        text: this.$t("thg.PriceTable.partnerId"),
        align: "start",
        value: "partnerId"
      },
      {
        text: this.$t("thg.PriceTable.countryCode"),
        align: "start",
        value: "countryCode"
      },
      {
        text: this.$t("thg.PriceTable.year"),
        align: "start",
        value: "year"
      },
      {
        text: this.$t("thg.PriceTable.isFixed"),
        align: "start",
        value: "isFixed"
      },
      {
        text: this.$t("thg.PriceTable.value"),
        align: "start",
        value: "value"
      },
      {
        text: this.$t("thg.PriceTable.commission"),
        align: "start",
        value: "commission"
      },
      { text: this.$t("thg.PriceTable.currency"), align: "start", value: "currency" },
      { text: this.$t("thg.PriceTable.unit"), align: "start", value: "unit" },
      { text: this.$t("thg.PriceTable.period"), align: "start", value: "startDate" },
      { text: this.$t("thg.PriceTable.id"), align: "start", value: "id" },
      { text: this.$t("thg.PriceTable.date"), align: "start", value: "timestamp.created" }
    ];
  }

  get prices(): ThgPriceViewModelGen[] {
    return PriceModule.paginationList;
  }

  get partners(): PartnerEntity[] {
    return PartnerModule.partners;
  }

  get loadingProgressSelected() {
    return (100 / this.selected.length) * this.progressCounter;
  }

  get controlElements(): IControlElements[] {
    return [];
  }

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  /**
   * Download prices
   */
  downloadPrices() {
    const exportData = [];
    for (const partner of this.partners) {
      const prices = this.prices.filter(price => price.partnerId == partner._id);
      let price = undefined;
      if (prices.length) {
        price = prices[0];
      }
      const entry = {
        partnerId: partner._id,
        companyName: partner.companyName,
        companyUsername: partner.companyUsername,
        country: partner.countryCode,
        year: price?.year,
        isFixed: price?.isFixed,
        isActive: price?.isActive,
        isArchived: price?.isArchived,
        value: price?.value,
        commission: price?.commission,
        start: price?.startDate,
        end: price?.endDate
      };
      exportData.push(entry);
    }

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Prices");
    XLSX.writeFile(workbook, "prices_" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  getPartner(partnerId: string) {
    return PartnerModule.partnersMap.get(partnerId);
  }

  cancel() {
    this.snack = true;
    this.snackColor = "info";
    this.snackText = "Abgebrochen";
  }

  open() {
    this.snack = true;
    this.snackColor = "info";
    this.snackText = "Ändern";
  }

  close() {
    this.$log.info("Dialog closed");
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoadingAll = true;
    try {
      await PriceModule.fetchAllFromBeginning({});
      await PartnerModule.getPartners();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoadingAll = false;
    }
  }

  async createPrice() {
    const createPrice = this.$data._createPrice;

    if (!createPrice) {
      this.$toast.error("no price");
      throw new Error("no price");
    }

    this.isDialogLoading = true;

    try {
      await PriceModule.create({ partnerId: createPrice.partner.id, data: createPrice.toDto() });

      this.$toast.success("👍");

      this.isCreateDialogActive = false;

      await this.load();
    } catch (error) {
      handleError(error);
    } finally {
      this.isDialogLoading = false;
    }
  }

  async activatePrice() {
    this.progressCounter = 0;
    this.isLoading = true;
    this.isDialogLoading = true;

    try {
      for (const select of this.selected) {
        await PriceModule.update({ partnerId: select.partnerId, id: select.id, data: { isActive: !select.isActive } });
        this.progressCounter++;
      }

      this.$toast.success("👍");

      this.isActivateDialogActive = false;
      this.progressCounter = 0;
      this.selected = [];

      await this.load();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
      this.isDialogLoading = false;
    }
  }

  async deletePrice() {
    this.isLoading = true;
    this.isDialogLoading = true;
    this.progressCounter = 0;

    try {
      for (const select of this.selected) {
        await PriceModule.remove({ partnerId: select.partnerId, id: select.id });
        this.progressCounter++;
      }
      this.$toast.success("👍");
      this.isDeleteDialogActive = false;
      this.selected = [];

      await this.load();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
      this.isDialogLoading = false;
    }
    this.progressCounter = 0;
  }

  async update(updated: ThgPriceViewModelGen) {
    this.isLoading = true;
    try {
      const updatePriceDto: ThgUpdatePriceDtoGen = {
        title: updated.title,
        unit: updated.unit,
        value: updated.value,
        commission: updated.commission,
        isFixed: updated.isFixed,
        currency: updated.currency,
        year: updated.year,
        startDate: updated.startDate,
        endDate: updated.endDate,
        isActive: updated.isActive
      };
      const response = await PriceModule.update({ partnerId: updated.partnerId, id: updated.id, data: updatePriceDto });

      if (!response) {
        this.$toast.error("Fehler beim Ändern des Preises");
      } else {
        this.$toast.success("Preis erfolgreich geändert.");
      }

      await this.load();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
