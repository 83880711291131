






































import { Component } from "vue-property-decorator";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { AdminInsuranceModule } from "@/store/modules/admin-insurance.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { ThgInsuranceForUserViewModelGen, ThgCreateInsuranceDtoGen } from "@/services/thg/v1/data-contracts";
import { simpleDate } from "@/lib/utility/date-helper";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import { mixins } from "vue-class-component";
import { IFullOptions } from "json-2-csv";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    ConfirmActionDialog
  }
})
export default class ThgInsuranceListView extends mixins(CsvExportMixin) {
  /**
   * Loading of first page
   */
  initialLoading = true;
  /**
   * If deletion is loading
   */
  isDeleteInsuranceLoading = false;
  /**
   * Is csv export loading
   */
  isExportLoading = false;
  /**
   * is delete dialog shown
   */
  isDeleteDialogActive = false;
  /**
   * If creation of insurances is loading
   */
  isCreateNewInsurancesLoading = false;
  /**
   * The element to delete
   */
  deleteItem: ThgInsuranceForUserViewModelGen | undefined;
  /**
   * Items that are selected in table
   */
  selected: ThgInsuranceForUserViewModelGen[] = [];
  /**
   * translations and such
   */
  get i18n() {
    return this.$t("views.ThgInsuranceListView") || {};
  }

  /**
   * checks if scren is super duper tiny
   */
  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  /**
   * The insurances
   */
  get insurances() {
    return AdminInsuranceModule.paginationList;
  }

  get headers() {
    return [
      {
        text: this.i18n["number"],
        value: "number"
      },
      {
        text: this.i18n["name"],
        value: "name"
      },
      {
        text: this.i18n["userId"],
        value: "userId"
      },
      {
        text: "",
        value: "controls"
      }
    ];
  }

  /**
   * The options in the rightmost column
   */
  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-trash-can",
        action: this.openDeleteInsuranceDialog,
        loading: this.isDeleteInsuranceLoading,
        text: this.i18n["delete"]
      }
    ];
  }

  /**
   * Opens dialog to delete passed item
   *
   * @param item
   */
  openDeleteInsuranceDialog(item: ThgInsuranceForUserViewModelGen) {
    this.deleteItem = item;
    this.isDeleteDialogActive = true;
  }

  /**
   * An empty insurance with userID
   */
  get emptyInsurance(): ThgCreateInsuranceDtoGen & { userId: string } {
    return {
      name: "",
      number: "",
      policyStartDate: undefined,
      policyEndDate: undefined,
      isActive: undefined,
      userId: ""
    };
  }

  /**
   * formats date
   * @param date
   */
  simpleDate(date: string) {
    return simpleDate(date);
  }

  /**
   * Loop new insurances into the backend
   * @param newInsurances
   */
  async confirmNewInsurances(newInsurances: (ThgCreateInsuranceDtoGen & { userId?: string })[]) {
    this.isCreateNewInsurancesLoading = true;
    try {
      for (const insurance of newInsurances) {
        const userId = insurance.userId || "";
        delete insurance.userId;
        await AdminInsuranceModule.create({ userId: userId, data: insurance });
      }
    } catch (e) {
      this.$toast((e as any).message);
      this.$log.error(e);
    } finally {
      this.isDeleteInsuranceLoading = false;
      this.isDeleteDialogActive = false;
      await this.load();
    }
  }

  /**
   * deletes item set @openDeleteInsuranceDialog
   */
  async deleteInsurance() {
    this.isDeleteInsuranceLoading = true;
    try {
      const userId = this.deleteItem?.userId;
      const insuranceId = this.deleteItem?.id;
      if (userId && insuranceId) {
        await AdminInsuranceModule.delete({ userId: userId, insuranceId: insuranceId });
      }
    } catch (e) {
      this.$toast((e as any).message);
      this.$log.error(e);
    } finally {
      this.isDeleteInsuranceLoading = false;
      this.isDeleteDialogActive = false;
      await this.load();
    }
  }

  /**
   * Load document pages
   */
  async load() {
    try {
      this.initialLoading = true;
      await AdminInsuranceModule.fetchAllFromBeginning({});
    } catch {
      this.$toast.error("Fehler beim Laden der Versicherungen");
    } finally {
      this.initialLoading = false;
    }
  }

  /**
   * Mounts component
   */
  async mounted() {
    await this.load();
  }

  /**
   * Go to user detail
   */
  openUserDetail(userId: string) {
    new GoToHelper(this.$router).goToUserDetail(userId);
  }

  /**
   * Export items to CSV
   * @param items
   */
  async exportItems(items: ThgInsuranceForUserViewModelGen[]) {
    try {
      this.isExportLoading = true;
      await this.download(items, this.options);
    } catch {
      this.$toast.error("Fehler beim Laden der Versicherungen");
    } finally {
      this.isExportLoading = false;
    }
  }

  /**
   * Options that make the CSV export work
   */
  options: IFullOptions = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ";", // Comma field delimiter
      eol: "\n" // Newline delimiter
    },
    trimFieldValues: true,
    excelBOM: true,
    unwindArrays: true
  };
}
